import './App.css';

function App() {
  return (
    <div className="App">
      <header className="App-header">
        <h3>
          LonewoodCandle 
        </h3>
        <p>LonewoodCandle@gmail.com</p>
        <p>More candles on the way ...</p>
      </header>
    </div>
  );
}

export default App;
